@import "../../styles/_chunk";

// TITLE AND BUTTON NAVIGATION

.FeaturedCarouselTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .FeaturedCarouselTitle__headingContainer {
    .FeaturedCarouselTitle__heading {
      margin-bottom: 0;
    }
  }
}

// MAIN CAROUSEL

.FeaturedCarousel {
  width: 100%;

  .FeaturedCarousel__slidesContainer {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    .slick-list {
      width: 100%; // IMPORTANT. This makes sure the carousel loads in full width.
    }

    .slick-active {
      z-index: 200; // IMPORTANT. When using the setting fade: true, this makes sure the active slide is always on top.
    }

    .slick-slide {
      > div:first-child {
        display: flex; // IMPORTANT. This is to format the empty div slick add
      }
    }

    .arrow-btn-container {
      &.btn-left {
        margin-left: -30px;

        @include media-breakpoint-up(xl) {
          margin-left: -40px;
        }
      }

      &.btn-right {
        margin-right: -30px;

        @include media-breakpoint-up(xl) {
          margin-right: -40px;
        }
      }
    }

    .FeaturedCarousel__arrows {
      &.btn-left {
        @include media-breakpoint-up(xxl) {
          margin-left: -45px;
        }
      }

      &.btn-right {
        @include media-breakpoint-up(xxl) {
          margin-right: -45px;
        }
      }

      .slick-arrow {
        @include media-breakpoint-up(xxl) {
          width: 40px;
          height: 40px;
          font-size: 1.5rem;
        }
      }
    }

    .FeaturedCarousel__slideContainer {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      border-radius: $border-radius-sm;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        height: 356px;
      }

      @include media-breakpoint-up(xl) {
        height: 422px;
      }

      @include media-breakpoint-up(xxl) {
        height: 496px;
      }
    }

    .FeaturedCarousel__image {
      width: 100%;
      flex-shrink: 0;
      position: relative;

      @include media-breakpoint-up(lg) {
        width: 66.666%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .responsive-image--16by9 {
        img {
          object-fit: cover;
        }
      }

      .FeaturedCarousel__image__mobileOverlay {
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;

        .StarDealProgressBar {
          width: 100%;

          .progress {
            border-radius: 0;
            color: $text-white;
            background-color: rgba($grey-13, 0.75);

            .progress-timer {
              font-weight: 700;
            }
          }

          .featured-deal-timer {
            display: none;
          }
        }

        .FlashSaleProgressBar {
          width: 100%;

          .progress {
            border-radius: 0;
            background-color: rgba($grey-13, 0.75);
          }
        }
      }

      .FeaturedCarousel__image__icons {
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 1;
        background-color: $card-background-dark;
        padding: 0.5rem;
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include theme(light) {
          background-color: map-get($light-theme, card-background);
        }

        .drm-container {
          margin-right: 1rem;
        }

        .FeaturedCarousel__image__icons__platform {
          margin-right: 0.5rem;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .FeaturedCarousel__image__price {
        position: absolute;
        bottom: 8px;
        right: 8px;
        z-index: 7;
        background-color: $card-background-dark;

        @include theme(light) {
          background-color: map-get($light-theme, card-background);
        }

        .FeaturedCarousel__image__price__value {
          color: $text-muted;
          text-align: center;
          margin-bottom: -8px;
        }

        .card-price-container {
          @include media-breakpoint-up(lg) {
            .card-saving {
              border-radius: 0;
              min-height: 40px;
            }

            .price-wasprice-container {
              padding: 5px;
            }
          }

          @include media-breakpoint-up(xl) {
            .card-saving {
              min-height: 49px;
              font-size: 1.125rem;
            }

            .card-price {
              font-size: 1.125rem;
            }

            .price-wasprice-container {
              padding: 8px;
              font-size: 1.125rem;
            }

            .was-price {
              margin-bottom: 4px;
            }
          }
        }

        &--value {
          .card-price-container {
            .price-wasprice-container {
              .card-price {
                color: $text-muted;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .FeaturedCarousel__carouselPanel {
      width: 100%;
      min-height: 110px;

      @include media-breakpoint-up(sm) {
        min-height: 130px;
      }

      @include media-breakpoint-up(lg) {
        width: 33.333%;
      }

      .FeaturedCarousel__carouselPanelLink {
        color: inherit;
        text-decoration: inherit;
        display: block;
        height: 100%;
        padding: 1rem;

        @include media-breakpoint-up(sm) {
          padding: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
          padding: 1.75rem;
        }

        @include media-breakpoint-up(xxl) {
          padding: 2rem;
        }
      }
    }
  }

  // SIDE PANEL GENERIC STYLES

  .CarouselPanel {
    height: 100%;
    display: flex;
    flex-direction: column;

    .CarouselPanel__topContainer {
      flex-grow: 1;
    }

    .CarouselPanel__button {
      background-color: $white;
      color: $text-black;
      border-radius: $border-radius-sm;
      text-align: center;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-transform: uppercase;
      transition: all 0.15s ease-in-out;

      &--primary {
        @include brand-property(background-color, brand-core);
        @include brand-property(color, font-color);

        @include brand(hpomen) {
          border-radius: 2px !important;
          font-family: 'Montserrat', 'Verdana', sans-serif;
          font-weight: 700;
          background-image: linear-gradient(to right, $hpomen-pink, $hpomen-red, $hpomen-orange);
          color: $white !important;
        }

        &:hover {
          @include brand-property(background-color, brand-dark);

          @include brand(hpomen) {
            background-image: linear-gradient(to right, $hpomen-core, $hpomen-core, $hpomen-core);
          }
        }
      }
    }

    .CarouselPanel__title {
      font-weight: 400;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 1rem;
      font-size: 1.125rem;

      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.375rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
        margin-bottom: 1.125rem;
      }

      @include media-breakpoint-up(xxl) {
        // font-size: 1.75rem;
      }

      a {
        color: inherit;
      }
    }

    .CarouselPanel__section {
      margin-bottom: 1rem;
      // margin-bottom: 1.25rem;

      @include media-breakpoint-up(xxl) {
        // margin-bottom: 1.375rem;
        margin-bottom: 1.25rem;
      }
    }

    .CarouselPanel__BadgeWishlistContainer {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 1rem;
      }

      .wishlist-btn {
        font-size: 13px;
        color: $text-muted;
        font-weight: 400;
        margin: 0;
        transition: color 0.15s ease-in-out;
        flex-shrink: 0;

        @include media-breakpoint-up(xl) {
          font-size: 14px;
        }

        svg {
          padding: 0;
          width: 15px !important;
          height: 15px !important;
          border-radius: 0;
          color: $text-muted !important;
          background-color: transparent;
          transition: color 0.15s ease-in-out;
        }

        &:hover {
          color: $primary-core;

          svg {
            color: $primary-core !important;
          }
        }
      }
    }

    .product-stars-critic-reviews-container {
      @extend .CarouselPanel__section;

      display: flex;
      align-items: center;

      .star-rating-container {
        margin-right: 0.25rem;

        .star {
          svg {
            width: 16px;

            &.star-half-icon {
              width: 8px;
            }
          }
        }
      }

      .user-review-score-no-tooltip {
        color: $text-muted;
        margin-right: 1rem;
      }
    }

    .CarouselPanel__shortDesc {
      color: $text-muted;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }

    .CarouselPanel__quickLook {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.75rem;

      .CarouselPanel__quickLook__title {
        font-weight: 400;
      }

      .CarouselPanel__quickLook__buttonContainer {
        button {
          font-size: 15px;
          padding: 0;
          margin: 0;
          border: 0;
          text-decoration: underline;
          background-color: transparent;
          color: $text-white;

          @include theme(light) {
            color: $text-black;
          }

          &:hover {
            @include brand-property(color, brand-core);
          }
        }
      }
    }

    .CarouselPanel__messageBlock {
      background-color: $black;
      text-align: center;
      text-transform: uppercase;
      font-size: 13px;
      display: none;
      padding: 0.25rem;
      min-height: 44px;

      @include theme(light) {
        background-color: $grey-3;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      @include media-breakpoint-up(xl) {
        font-size: 14px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 15px;
      }

      .primary-message {
        font-weight: 700;
        font-size: 15px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 22px;
        }
      }
    }

    .StarDealProgressBar {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }

      .progress {
        margin-bottom: 0.75rem;

        @include theme(light) {
          background-color: $grey-4;
        }

        .progress-timer {
          font-weight: 700;

          @include theme(light) {
            color: $text-white;
          }
        }
      }

      .featured-deal-timer {
        color: $text-muted;
        font-weight: 400;
        font-size: 14px;

        span {
          color: $stardeal-core;
        }
      }
    }

    .TagSection {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      margin-left: -0.25rem;
      margin-right: -0.25rem;
      margin-top: -0.25rem;

      .TagSection__tagColumn {
        padding: 0.25rem;

        // DOWN
        @include media-breakpoint-down(xl) {
          &:last-of-type {
            display: none;
          }
        }
      }

      .TagSection__tag {
        background-color: $grey-10;
        color: $text-white;
        border-radius: $border-radius-sm;
        font-size: 12px;
        font-weight: 400;
        padding: 0.125rem 0.375rem;
        white-space: pre;

        @include theme(light) {
          background-color: $grey-3;
          color: $text-black;
        }

        &:hover {
          background-color: $grey-9;

          @include theme(light) {
            background-color: $grey-4;
          }
        }
      }
    }

    .CarouselPanel__bottomContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .CarouselPanel__bottomContainer__priceButton {
        display: flex;
      }

      .AddToCartBtn {
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        padding: 0.25rem 0.5rem;

        @include media-breakpoint-only(lg) {
          display: none !important;
        }

        @include media-breakpoint-up(xl) {
          flex-direction: column;
          padding: 0.25rem 0.75rem;
          min-width: 54px;
        }

        @include media-breakpoint-up(xxl) {
          min-height: 44px;
        }

        .btn-text-container {
          text-transform: uppercase;
          font-weight: 700;

          @include media-breakpoint-up(xl) {
            margin-top: 2px;
            font-size: 12px;
            line-height: 12px;
          }
        }

        .cart-btn-icon {
          display: flex;
          font-size: 13px;

          @include media-breakpoint-up(xl) {
            font-size: 14px;
          }

          svg {
            margin-right: 0.375rem;

            @include media-breakpoint-up(xl) {
              margin-right: 0 !important;
            }
          }
        }

        &--iconOnly {
          .cart-btn-icon {
            @include media-breakpoint-up(xl) {
              font-size: 17px;
            }
          }
        }
      }

      .PlatformIcons {
        display: none;

        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
        }

        .PlatformIcons__osIcons {
          .PlatformIcons__osIcon {
            font-size: 0.85rem;
            margin-right: 0.5rem;

            @include media-breakpoint-up(xl) {
              font-size: 1rem;
            }

            &:last-of-type {
              margin-right: 1rem;
              @include media-breakpoint-only(lg) {
                margin-right: 0;
              }
            }

            @include theme(light) {
              color: map-get($light-theme, card-icons-color);
            }
          }
        }

        .PlatformIcons__drm {
          margin-right: 1rem;
        }

        .PlatformIcons__bundleType {
          display: flex;
          align-items: center;
          margin-right: 1rem;

          @include theme(light) {
            color: map-get($light-theme, card-icons-color);
          }

          svg {
            margin-right: 0.5rem;
          }

          .PlatformIcons__bundleType__text {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }

      .card-price-container {
        .card-saving {
          position: relative !important;
          margin-left: 0 !important;
          font-size: 14px;
          line-height: 14px;
          min-height: 38px;
          min-width: 52px;

          @include media-breakpoint-up(xl) {
            font-size: 16px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 18px;
            line-height: 18px;
            min-height: 44px;
            min-width: 58px;
          }
        }

        .price-wasprice-container {
          font-size: 14px;
          line-height: 14px;

          @include media-breakpoint-up(xl) {
            font-size: 16px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 18px;
            line-height: 18px;
          }

          .from-container {
            font-size: 14px;
            line-height: 14px;
            text-align: left;

            @include media-breakpoint-up(lg) {
              text-align: right;
            }
          }
        }
      }
    }
  }

  // DARK THEME CAROUSEL

  &--dark {
    .FeaturedCarousel__carouselPanel {
      background-color: $card-background-dark;

      .ScreenshotCarousel {
        .ScreenshotCarousel__column {
          .ScreenshotCarousel__imgContainer {
            .ScreenshotCarousel__imgContainer__leftBorder,
            .ScreenshotCarousel__imgContainer__rightBorder {
              background-color: $card-background-dark;
            }
          }
        }
      }
    }
  }

  // LIGHT THEME CAROUSEL

  &--light {
    box-shadow: map-get($light-theme, card-box-shadow);
    border-radius: $border-radius;

    .FeaturedCarousel__carouselPanel {
      background-color: map-get($light-theme, card-background);

      .ScreenshotCarousel {
        .ScreenshotCarousel__column {
          .ScreenshotCarousel__imgContainer {
            .ScreenshotCarousel__imgContainer__leftBorder,
            .ScreenshotCarousel__imgContainer__rightBorder {
              background-color: map-get($light-theme, card-background);
            }
          }
        }
      }
    }
  }
}

// PAGINATION

.FeaturedCarousel__pagination {
  position: absolute;
  bottom: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: -24px;

  li {
    padding: 0 4px;
    display: flex;

    button {
      height: 12px;
      width: 12px;
      border: 0;
      border-radius: 50%;
      background-color: $grey-7;
      color: transparent;

      @include theme(light) {
        background-color: $grey-4;
      }
    }

    &.slick-active {
      button {
        background-color: $white;

        @include theme(light) {
          background-color: $grey-7;
        }
      }
    }
  }
}
