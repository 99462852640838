@import "../../../../styles/_chunk";

// COLOUR OPTIONS
// $label-primary
// $label-stardeal
// $label-purple
// $label-green
// $label-red
// $label-orange
// $label-navy

.BadgeSlash {
  min-height: 24px;
  display: flex;

  .BadgeSlash__label {
    background-color: $white;
    color: $text-black;
    text-transform: uppercase;
    padding: 2px 8px;
    padding-right: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.1px;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-bottom: 2px;
      margin-right: 2px;

      @include media-breakpoint-up(xl) {
        width: 18px;
        height: 18px;
      }

      @include media-breakpoint-up(xxl) {
        width: 20px;
        height: 20px;
      }
    }
  }

  .BadgeSlash__angle {
    width: 24px;
    height: 100%;
    background: linear-gradient(-66deg, transparent 0%, transparent 49%, $white 51%, $white 100%);
  }

  // STAR DEAL

  &--starDeal {
    .BadgeSlash__label {
      background-color: $label-stardeal;
    }

    .BadgeSlash__angle {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-stardeal 51%, $label-stardeal 100%);
    }
  }

  // FLASH DEAL

  &--flashDeal {
    .BadgeSlash__label {
      background-color: $label-red;
      color: $text-white;
    }

    .BadgeSlash__angle {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-red 51%, $label-red 100%);
    }
  }

  // PRE-ORDER
  &--product,
  &--newRelease,
  &--preOrder {
    .BadgeSlash__label {
      background-color: $label-green;
      color: $text-white;
    }

    .BadgeSlash__angle {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-green 51%, $label-green 100%);
    }
  }

  // JUST LAUNCHED
  &--justLaunched {
    .BadgeSlash__label {
      background-color: $label-orange;
      color: $text-white;
    }

    .BadgeSlash__angle {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-orange 51%, $label-orange 100%);
    }
  }

  // BUNDLE
  &--bundle {
    .BadgeSlash__label {
      background-color: $label-primary;
    }

    .BadgeSlash__angle {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-primary 51%, $label-primary 100%);
    }
  }

  // CATEGORY
  &--categorySale,
  &--publisherSale,
  &--franchiseSale,
  &--siteWideSale, {
    .BadgeSlash__label {
      background-color: $label-navy;
      color: $text-white;
    }

    .BadgeSlash__angle {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-navy 51%, $label-navy 100%);
    }
  }
}
