@import "../../../../styles/_chunk";

.ScreenshotCarousel {
  z-index: 1;

  .ScreenshotCarousel__column {
    .ScreenshotCarousel__imgContainer {
      margin-bottom: 0.5rem;
      position: relative;
      display: block;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        // display: contents;
      }

      img {
        filter: brightness(0.75);
        width: 100%;

        @include theme(light) {
          filter: brightness(1);
        }

        &.portrait-image-background {
          filter: blur(6px) brightness(0.5) !important;

          @include theme(light) {
            filter: blur(6px) brightness(0.75) !important;
          }
        }
      }

      &:hover {
        img {
          filter: brightness(1);

          @include theme(light) {
            filter: brightness(0.75);
          }

          &.portrait-image-background {
            filter: blur(6px) brightness(0.75) !important;

            @include theme(light) {
              filter: blur(6px) brightness(0.5) !important;
            }
          }
        }
      }

      .ScreenshotCarousel__imgContainer__leftBorder,
      .ScreenshotCarousel__imgContainer__rightBorder {
        position: absolute;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: $black;
        z-index: 4;
      }

      .ScreenshotCarousel__imgContainer__leftBorder {
        left: 0;
      }

      .ScreenshotCarousel__imgContainer__rightBorder {
        right: 0;
      }
    }
  }

  .slick-slide {
    &:first-of-type {
      .ScreenshotCarousel__imgContainer {
        .ScreenshotCarousel__imgContainer__leftBorder {
          display: none;
        }
      }
    }

    &:last-of-type {
      .ScreenshotCarousel__imgContainer {
        .ScreenshotCarousel__imgContainer__rightBorder {
          display: none;
        }
      }
    }

    > div:first-child {
      display: flex; // This is to format the empty div slick add
    }
  }

  .arrow-btn-container {
    display: none !important;

    .slick-arrow {
      height: 100% !important;
      background-color: rgba($black, 0.7) !important;
      border-radius: 0 !important;
      transition: ease all 0.15s;
    }

    .slick-disabled {
      display: none;
    }

    &.btn-right {
      margin-right: 0 !important;
    }

    &.btn-left {
      margin-left: 0 !important;
    }
  }

  &:hover {
    .arrow-btn-container {
      display: flex !important;
    }
  }
}
