@import "../../../../styles/_chunk";

.FlashSaleProgressBar {
  position: relative;

  .FlashSaleProgressBar__timer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    text-shadow: 1px 0 5px rgba($grey-14, 0.5), 1px 0 5px rgba($grey-14, 0.5);

    @include theme(light) {
      color: $text-white;
    }

    .FlashSaleProgressBar__timer__text {
      margin-right: 0.25rem;
    }

    .sd-timer {
      @include theme(light) {
        font-weight: 700;
      }
    }
  }

  .FlashSaleProgressBar__progress {
    .progress {
      @include theme(light) {
        background-color: $grey-4;
      }

      .progress-bar {
        background-color: $progress-red;
      }
    }
  }
}
